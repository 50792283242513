import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
    Button,
    Paper,
    Typography,
    TextField,
    InputAdornment,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    List,
    ListItem,
    ListItemText,
    Divider,
    ListItemIcon,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import Title from "../../components/Title";
import { AuthContext } from "../../context/Auth/AuthContext";
import api from "../../services/api";
import toastError from "../../errors/toastError";


const filaOptions = require('./FILAS_DE_ATENDIMENTO.png');
const centerchatManual = require('./PASSO_A_PASSO_CONFIGURACAO_CENTERCHAT.pdf');
const exChatbot = require('./EX_CHATBOT.png');
const chatbotManual = require('./PASSO_A_PASSO_CHATBOT.pdf');

const useStyles = makeStyles((theme) => ({
    mainPaper: {
        flex: 1,
        padding: theme.spacing(1),
        overflowY: "scroll",
        ...theme.scrollbarStyles,
    },
    queueBox: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: 'center',
        maxWidth: "30ch",
        maxHeight: "85px",
        overflowY: "auto",
        ...theme.scrollbarStyles,
    },
    heading: {
        fontSize: "1.1rem",
        fontWeight: 600,
    },
    secondaryHeading: {
        fontSize: "0.9rem",
        fontWeight: 400,
    },
}));

const initialFaqItems = [
    {
        id: 'panel1',
        question: 'Como configurar o Centerchat?',
        answer: (
            <div>
                <Typography>
                    Passo a Passo para Configuração Inicial do CenterChat
                </Typography>
                <Typography style={{ fontWeight: 600, marginTop: 10 }}>
                    1. Criar as Filas
                </Typography>
                <Typography style={{ marginTop: 10 }}>
                    As filas são categorias que organizam o atendimento dentro do sistema. Elas ajudam a direcionar
                    os tickets para equipes ou operadores específicos, conforme o tipo de solicitação ou cliente.
                    Por exemplo, você pode criar filas como Suporte Técnico, Vendas, ou Financeiro.
                </Typography>
                <Typography style={{ fontWeight: 600, marginTop: 10 }}>
                    Para criar uma fila, siga os passos abaixo:
                </Typography>
                <List>
                    <ListItem>
                        <ListItemIcon>
                            <Typography>1.</Typography>
                        </ListItemIcon>
                        <ListItemText primary="Clique no botão 'Criar Fila' no menu lateral" />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <Typography>2.</Typography>
                        </ListItemIcon>
                        <ListItemText
                            primary="Preencha os campos com o nome da fila, cor e a mensagem de saudação que será enviada quando o cliente selecionar essa fila."
                            secondary="A mensagem de saudação é opcional, mas recomendada para personalizar o atendimento."
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <Typography>3.</Typography>
                        </ListItemIcon>
                        <ListItemText primary="Clique em 'Salvar'" />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="- abaixo da caixa de entrada da mensagem de saudação, você pode adicionar opções para configurar o 'ChatBot'." />
                    </ListItem>
                </List>
                <Typography style={{ fontWeight: 600, marginTop: 10 }}>
                    2. Criar a Conexão
                </Typography>
                <Typography style={{ marginTop: 10 }}>
                    A conexão é o vínculo entre o CenterChat e o número de WhatsApp utilizado para o atendimento.
                    Sem essa configuração, o sistema não pode enviar ou receber mensagens.
                </Typography>
                <Typography style={{ fontWeight: 600, marginTop: 10 }}>
                    Como criar uma conexão:
                </Typography>
                <List>
                    <ListItem>
                        <ListItemIcon>
                            <Typography>1.</Typography>
                        </ListItemIcon>
                        <ListItemText primary="Clique em 'Conexões' no menu lateral esquerdo" />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <Typography>2.</Typography>
                        </ListItemIcon>
                        <ListItemText primary="Clique no botão 'Adicionar Conexão'" />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <Typography>3.</Typography>
                        </ListItemIcon>
                        <ListItemText primary="Preencha os campos com o nome da conexão, se é a conexão padrão, mensagem de saudação, mensagem de despedida (não obrigatório) e as filas que essa conexão terá acesso."
                            secondary="A mensagem de saudação é a mensagem que será enviada automaticamente quando o cliente entrar em contato. A mensagem de despedida é a mensagem que será enviada quando o cliente encerrar o atendimento. As filas são enviadas como opção quando o cliente fizer contato."
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <Typography>4.</Typography>
                        </ListItemIcon>
                        <ListItemText primary="Clique em 'Salvar'" />
                    </ListItem>
                </List>
                <Typography style={{ fontWeight: 600, marginTop: 10 }}>
                    3. Criar os Usuários
                </Typography>
                <Typography style={{ marginTop: 10 }}>
                    Os usuários são as pessoas que terão acesso ao sistema para realizar atendimentos ou gerenciar configurações. Cada usuário
                    deve ter um nível de acesso apropriado para suas funções.
                </Typography>
                <Typography style={{ fontWeight: 600, marginTop: 10 }}>
                    São 3 níveis de acesso:
                </Typography>
                <List>
                    <ListItem>
                        <ListItemText primary="Administrador (admin)"
                            secondary="Tem acesso total ao sistema, podendo realizar todas as configurações e gerenciar usuários." />

                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Supervisor (superv)"
                            secondary="Tem acesso limitado ao sistema, podendo realizar configurações específicas, como conexão e gereciamento de todos os tickets."
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Usuário (user)"
                            secondary="Tem permissões limitadas e se concentra principalmente na execução das tarefas de atendimento ao cliente."
                        />
                    </ListItem>
                </List>
                <Typography style={{ fontWeight: 600, marginTop: 10 }}>
                    Como criar um usuários:
                </Typography>
                <List>
                    <ListItem>
                        <ListItemIcon>
                            <Typography>1.</Typography>
                        </ListItemIcon>
                        <ListItemText primary="Clique em 'Usuários' no menu lateral esquerdo" />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <Typography>2.</Typography>
                        </ListItemIcon>
                        <ListItemText primary="Preencha os campos com o nome, e-mail, senha, nível de acesso do usuário e quais filas esse usuário terá acesso e será responsável pelo atendimento." />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <Typography>3.</Typography>
                        </ListItemIcon>
                        <ListItemText primary="Clique em 'Salvar'" />
                    </ListItem>
                </List>

                <Button variant="contained" color="primary" style={{ marginTop: 10 }}
                    onClick={() => window.open(centerchatManual)} >
                    Baixar Manual de Configuração
                </Button>


                <Typography style={{ fontWeight: 600, marginTop: 10 }}>
                    Nesse arquivo, você encontrará mais informações sobre como configurar e utilizar o CenterChat.
                </Typography>
            </div>
        ),
    },
    {
        id: 'panel2',
        question: 'Como cadastrar respostas rápidas?',
        answer: (
            <div>
                <Typography>
                    Siga os passos abaixo para cadastrar respostas rápidas:
                </Typography>
                <List>
                    <ListItem>
                        <ListItemText primary="1. No menu lateral do sistema, selecione a opção 'Respostas Rápidas'." />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="2. Clique em 'Adicionar Nova Resposta'." />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="3. Insira o texto da resposta e/ou um arquivo (imagem ou pdf)." />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="4. Associe um atalho para usá-la facilmente durante as conversas." />
                    </ListItem>
                </List>
            </div>
        ),
    },
    {
        id: 'panel3',
        question: 'Como utilizar as respostas rápidas?',
        answer: (
            <Typography>
                Durante uma conversa, digite o atalho associado à resposta rápida (“/”), dessa forma irá listar todas que
                estão cadastradas. Pode digitar também junto a “/” o inicio do atalho para filtrar. Clique na resposta
                rápida que deseja enviar. O texto configurado será inserido automaticamente no chat, economizando tempo
                em interações frequentes.
            </Typography>
        ),
    },
    {
        id: 'panel4',
        question: 'Como cadastrar tags?',
        answer: (
            <div>
                <Typography>
                    Siga os passos abaixo para cadastrar tags:
                </Typography>
                <List>
                    <ListItem>
                        <ListItemText primary="1. No menu lateral do sistema, selecione a opção 'Tags'." />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="2. Clique em 'Adicionar Nova Tag'." />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="3. Insira o nome da tag e selecione uma cor." />
                    </ListItem>
                </List>
            </div>
        ),
    },
    {
        id: 'panel5',
        question: 'Para que serve as tags e como utilizar?',
        answer: (
            <div>
                <Typography>
                    As tags ajudam a categorizar e organizar tickets e contatos, facilitando buscas e análises. Você
                    pode aplicá-las manualmente em tickets ou configurá-las para serem atribuídas automaticamente com
                    base em regras predefinidas.
                </Typography>
                <br />
                <Typography>
                    Exemplo de utilização:
                </Typography>
                <Typography>
                    - Após uma conversa, você pode adicionar uma tag para identificar o motivo do contato, como
                    "Pagamento Pendente", "Agendado" entre outros.
                </Typography>
                <br />
                <Typography>
                    Como isso pode ajudar posteriormente:
                </Typography>
                <List>
                    <ListItem>
                        <ListItemText primary="1. Facilita a busca de tickets com base em critérios específicos." />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="2. Priorização no Atendimento: A equipe pode buscar tickets com a tag #PagamentoPendente e tratá-los como prioridade, garantindo que problemas financeiros sejam resolvidos rapidamente." />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="3. Relatórios e Análise: Com as tags, é possível gerar relatórios personalizados para análise de desempenho e identificação de oportunidades de melhoria." />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="4. Segmentação de Clientes. Um exemplo: É possível identificar clientes que tiveram problemas com pagamento e criar uma comunicação específica para evitar recorrências envio de instruções detalhadas sobre pagamento, etc)." />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="5. Treinamento e Melhoria Contínua: Com base nas tags, é possível identificar padrões de problemas recorrentes e investir em treinamentos específicos para a equipe, melhorando a qualidade do atendimento." />
                    </ListItem>
                </List>
            </div>
        ),
    },
    {
        id: 'panel6',
        question: 'Como faço para buscar um ticket?',
        answer: (
            <div>
                <Typography>
                    Para buscar um ticket, acesse o menu “Tickets” e utilize a barra de pesquisa. Você pode buscar por
                    mensagem, nome do contato, número de telefone, tags, fila do ticket, usuário e entre outros critérios.
                </Typography>
                <br />
                <Typography>
                    Exemplo de busca:
                </Typography>
                <Typography>
                    - Se você deseja buscar um ticket pelo contato, digite "@" e o nome e/ou número do contato no campo de pesquisa.
                </Typography>
                <Typography>
                    - Se deseja buscar por um nome de usuário específico (atendente), digite "!" e o nome no campo de pesquisa.
                </Typography>
                <Typography>
                    - Se deseja buscar por alguma mensagem ou palavra específica que esteja em alguma conversa, digite a mensagem no campo de pesquisa.
                </Typography>
            </div>
        ),
    },
    {
        id: 'panel7',
        question: 'Não consigo cadastrar um contato, o que fazer?',
        answer: (
            <div>
                <Typography>
                    Antes de tentar cadastrar, siga os passos abaixo:
                </Typography>
                <br />
                <Typography>
                    1. Verifique se o contato já está cadastrado:
                </Typography>
                <List>
                    <ListItem>
                        <ListItemText primary="- Acesse o menu 'Contatos'." />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="- Utilize o campo de busca para digitar o número completo ou parte dele e confirme se o contato já está registrado no sistema." />
                    </ListItem>
                </List>
                <Typography>
                    2. Certifique-se de que os campos obrigatórios foram preenchidos corretamente:
                </Typography>
                <List>
                    <ListItem>
                        <ListItemText primary="- Preencha todas as informações obrigatórias solicitadas no formulário de cadastro. Nome e número" />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="- Verifique se o número de telefone está no formato internacional correto (código do país + DDD + número)." />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="- Confirme que o número informado está associado a uma conta de WhatsApp ativa." />
                    </ListItem>
                </List>
                <Typography>
                    3. Caso o problema persista, entre em contato com o suporte técnico do CenterChat.
                </Typography>
            </div>
        ),
    },
    {
        id: 'panel8',
        question: 'Como faço para enviar de forma automática as opções quando o cliente entra em contato?',
        answer: (
            <div>
                <Typography>
                    O primeiro passo é cadastrar as filas de atendimento. Elas que serão as opções apresentadas
                    ao cliente. Para isso, siga os passos abaixo:
                </Typography>
                <br />
                <List>
                    <ListItem>
                        <ListItemText primary="1. No menu lateral do sistema, selecione a opção 'Filas'." />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="2. Clique em 'Adicionar Nova Fila'." />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="3. Insira o nome da fila e a mensagem de saudação que será enviada automaticamente quando o cliente escolher essa fila como opção." />
                    </ListItem>
                </List>
                <Typography>
                    Após criar as filas, você precisará configurar a regra de atendimento para que as opções sejam enviadas automaticamente.
                </Typography>
                <List>
                    <ListItem>
                        <ListItemText primary="1. No menu lateral do sistema, vá em 'Conexões'." />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="2. Clique no incone de lápis na conexão do WhatsApp que deseja configurar." />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="3. Irá abrir uma tela com as configurações da conexão. Vá ao final da tela e clique em 'Filas'." />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="4. Selecione as filas que deseja que seja enviada como opção para o cliente."
                            secondary="Conforme imagem abaixo:"
                        />
                    </ListItem>
                </List>
                <img src={filaOptions} alt="Fila de Atendimento" height="50%" width="auto" />
            </div>
        ),
    },
    {
        id: 'panel9',
        question: 'Como alterar a minha senha?',
        answer: (
            <div>
                <Typography>
                    Para alterar a sua senha, você pode seguir os passos abaixo:
                </Typography>
                <List>
                    <ListItem>
                        <ListItemText primary="1. Na barra superior da tela, clique no ícone ao lado do seu nome de usuário." />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="2. Clique em 'Perfil'." />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="3. No campo 'Senha', digite sua nova senha e clique em 'Salvar'." />
                    </ListItem>
                </List>
            </div>
        ),
    },
    {
        id: 'panel10',
        question: 'Como posso adicionar ou remover membros da minha equipe no sistema?',
        answer: (
            <div>
                <Typography>
                    Para adicionar ou remover membros da sua equipe, você pode seguir os passos abaixo:
                </Typography>
                <List>
                    <ListItem>
                        <ListItemText primary="1. No menu lateral do sistema, selecione a opção 'Usuários'." />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="2. Clique no botão 'Adicionar Usuário' ou no ícone de lápis para editar um usuário já cadastrado." />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="3. Preencha as informações solicitadas e clique em 'Salvar'." />
                    </ListItem>
                </List>
                <Typography>
                    <strong>Recomendamos não excluir usuários do sistema</strong>, apenas desativá-los ou alterar os dados. Dessa forma, você mantém o histórico
                    de atendimentos e informações importantes.
                </Typography>
            </div>
        ),
    },
    {
        id: 'panel11',
        question: 'Por que minha conta do WhatsApp foi desconectada e como posso reconectá-la?',
        answer: (
            <div>
                <Typography>
                    Isso pode ocorrer devido à expiração da sessão ou problemas na conexão à API. Para reconectar, siga os passos abaixo:
                </Typography>
                <List>
                    <ListItem>
                        <ListItemText primary="1. Verifique se você está conectado à internet." />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="2. No menu lateral do sistema, selecione a opção 'Conexões'." />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="3. Clique em 'QrCode' na conexão do WhatsApp que deseja reconectar." />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="4. Escaneie o QR Code com o aplicativo do WhatsApp em seu celular." />
                    </ListItem>
                </List>
            </div>
        ),
    },
    {
        id: 'panel12',
        question: 'O que fazer se as mensagens não forem entregues aos contatos?',
        answer: (
            <div>
                <Typography>
                    Verifique a sua conexão com a internet. Verifique também se a conexão do WhatsApp está ativa e se os
                    números dos destinatários estão corretos.Caso o problema persista, contate o suporte.
                </Typography>
                <Typography>
                    Lembre-se de manter o aparelho celular que está o whatsapp sempre carregado e com acesso a internet.
                </Typography>
                <br />
                <Typography>
                    Caso o problema persista, contate o suporte.
                </Typography>
            </div>
        ),
    },
    {
        id: 'panel13',
        question: 'O que é o chat interno e pra que serve?',
        answer: (
            <Typography>
                O chat interno é uma ferramenta para a comunicação direta entre os membros da equipe dentro do CenterChat.
                Ele serve para facilitar a troca de informações, alinhar demandas, envio de arquivos e colaborar em tempo
                real sem a necessidade de utilizar outras plataformas externas ou até mesmo um número de contato.
            </Typography>
        ),
    },
    {
        id: 'panel14',
        question: 'O que é o ChatBot e como configurar?',
        answer: (
            <div>
                <Typography>
                    O chatbot do CenterChat funciona de forma simples, como um fluxo de opções que guia o cliente passo a passo.
                    Ele é configurado dentro de cada fila, e as opções aparecem automaticamente para o cliente conforme ele faz suas
                    escolhas. É como se fosse um fluxograma, onde cada escolha leva a outra etapa do atendimento.
                </Typography>
                <Typography style={{ fontWeight: 600, marginTop: 10 }}>
                    Como Configurar o Chatbot?
                </Typography>
                <List>
                    <ListItem>
                        <ListItemIcon>
                            <Typography>1.</Typography>
                        </ListItemIcon>
                        <ListItemText primary="Escolha a fila para configurar o ChatBot" />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <Typography>2.</Typography>
                        </ListItemIcon>
                        <ListItemText
                            primary="Dentro da fila, adicione as opções que o cliente receberá ao entrar em contato."
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <Typography>3.</Typography>
                        </ListItemIcon>
                        <ListItemText primary="Crie sub-opções (se necessário). Dentro de cada opção, você pode adicionar mais opções para detalhar o atendimento." />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <Typography>4.</Typography>
                        </ListItemIcon>
                        <ListItemText primary="Repita o processo, se houver mais etapas. Continue configurando até que o fluxo do chatbot esteja completo." />
                    </ListItem>
                </List>
                <Typography>
                    Exemplo de Configuração:
                </Typography>
                <img src={exChatbot} alt="Exemplo de ChatBot" height="50%" width="auto"
                    style={{ display: 'block', margin: '0 10px', padding: 10 }} />
                <Button variant="contained" color="primary" style={{ marginTop: 10 }}
                    onClick={() => window.open(chatbotManual)}>
                    Acessar Manual Completo do ChatBot
                </Button>
                <Typography style={{ fontWeight: 600, marginTop: 10 }}>
                    Por que o ChatBot é importante?
                </Typography>
                <List>
                    <ListItem>
                        <ListItemIcon>
                            <Typography>1.</Typography>
                        </ListItemIcon>
                        <ListItemText primary="Ele ajuda o cliente a encontrar rapidamente o que precisa, sem confusão." />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <Typography>2.</Typography>
                        </ListItemIcon>
                        <ListItemText primary="Reduz o trabalho manual, organizando o atendimento de forma automática." />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <Typography>3.</Typography>
                        </ListItemIcon>
                        <ListItemText primary="Permite criar caminhos específicos para cada tipo de solicitação, otimizando o processo." />
                    </ListItem>
                </List>

                <Typography style={{ marginTop: 10, marginBottom: 10 }}>
                    Agora que você sabe como configurar o ChatBot, é hora de criar o seu próprio para melhorar a experiência do cliente em sua empresa!
                </Typography>

            </div>
        ),
    },
];

const Faq = () => {
    const classes = useStyles();
    const [expanded, setExpanded] = useState(false);
    const [searchParam, setSearchParam] = useState('');
    const [filteredFaqs, setFilteredFaqs] = useState(initialFaqItems);
    const [loading, setLoading] = useState(false);


    const history = useHistory();
    const { user } = useContext(AuthContext);

    const [centerchatContact, setCenterchatContact] = useState("");

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    useEffect(() => {
        const filteredFaqs = initialFaqItems.filter((faq) => faq.question.toLowerCase().includes(searchParam.toLowerCase()));
        setFilteredFaqs(filteredFaqs);
    }, [searchParam]);

    useEffect(() => {
        setLoading(true);
        const delayDebounceFn = setTimeout(() => {
            const fetchData = async () => {

                try {
                    const response = await api.get("/centerchat/contacts", {
                        params: { number: "5527992882288" },
                    });

                    const contact = response.data;

                    console.log(contact);

                    setCenterchatContact(contact);


                } catch (err) {
                    console.error(err);
                    toastError(err);
                }
                setLoading(false);
            };
            fetchData();

        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, []);

    const handleSearch = (event) => {
        setSearchParam(event.target.value.toLowerCase());
    };

    const handleNewChat = async () => {
        try {
            const { data: ticket } = await api.post("/tickets", {
                contactId: centerchatContact.id,
                userId: user.id,
                status: "open",
            });
            history.push(`/tickets/${ticket.id}`);
        } catch (err) {
            toastError(err);
        }
    }

    return (
        <MainContainer>
            <MainHeader>
                <Title>Perguntas Frequentes</Title>
                <MainHeaderButtonsWrapper>
                    <TextField
                        variant="outlined"
                        size="small"
                        placeholder="Pesquisar"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                        value={searchParam}
                        onChange={handleSearch}
                    />
                </MainHeaderButtonsWrapper>
            </MainHeader>
            {!loading && (
                <Paper
                    className={classes.mainPaper}
                    variant="outlined"
                >
                    {filteredFaqs.map((item) => (
                        <Accordion
                            key={item.id}
                            expanded={expanded === item.id}
                            onChange={handleChange(item.id)}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={`${item.id}-content`}
                                id={`${item.id}-header`}
                            >
                                <Typography className={classes.heading}>{item.question}</Typography>
                            </AccordionSummary>
                            <Divider />
                            <AccordionDetails className={classes.secondaryHeading}>{item.answer}</AccordionDetails>
                        </Accordion>
                    ))}

                    <div style={{ padding: 20, justifyContent: "left" }}>
                        <Typography style={{ fontWeight: 600, marginTop: 10, fontSize: "1.1rem", marginBottom: 10 }}>
                            Não encontrou a resposta que procurava? Entre em contato com o suporte técnico do CenterChat.
                        </Typography>
                        <Typography>
                            Você pode enviar uma mensagem no nosso Whatsapp: (27) 99288-2288 ou clicando no botão abaixo:
                        </Typography>
                        <Button
                            variant="contained"
                            color="primary"
                            style={{ margin: 10 }}
                            onClick={handleNewChat}
                        >
                            Entrar em contato
                        </Button>
                        <br />
                        <Typography>
                            Ou enviar um e-mail para: atendimento@centerchat.com.br
                        </Typography>
                        <br />
                        <Typography>
                            Horário de atendimento: Segunda a Quinta, das 08h às 18h e Sexta, das 08h às 17h.
                        </Typography>
                    </div>

                </Paper>
            )}
        </MainContainer>
    )
};
export default Faq;